<template>
  <div>
    <b-card>
      <b-row class="mb-1">
        <b-col><b-card-title>Şirketlerim</b-card-title></b-col>
        <b-col cols="auto">
          <b-button
            variant="primary"
            to="/yatirimci-profili/yeni-olustur"
          >
            Yeni Oluştur
          </b-button>
        </b-col>
      </b-row>
      <b-table
        responsive="sm"
        :fields="fields"
        :items="profileList"
        striped
        fixed
      >
        <template #cell(control)="data">
          <b-button
            :to="'/yatirimci-profili/guncelle/' + data.item.id"
            variant="outline-primary"
            size="sm"
          >
            Güncelle
          </b-button>
        </template>
      </b-table>
    </b-card>
  </div>
</template>

<script>
import {
  BTable, BCard, BRow, BCol, BButton, BCardTitle,
} from 'bootstrap-vue'

export default {
  name: 'Index',
  components: {
    BTable, BCard, BRow, BCol, BButton, BCardTitle,
  },
  data() {
    return {
      fields: [
        {
          key: 'company',
          label: 'FİRMA ADI',
        },
        {
          key: 'control',
          label: '',
          thStyle: { width: '140px' },
        },
      ],
    }
  },
  computed: {
    profileList() {
      return this.$store.getters['investorProfile/getInvestorProfile']
    },
  },
  created() {
    this.getProfileList()
  },
  methods: {
    getProfileList() {
      this.$store.dispatch('investorProfile/investorProfileList')
    },
  },
}
</script>

<style scoped></style>
